<template>
<div v-if="loaded" class="opacity-page">
	<div class="settings-content">
		<div class="list-crud profile row-space-tbf">
			<div class="space-left"></div>
			<div class="content form-group profile">
				<div class="data-group list">
					<div class="input-group profile">
						<!-- <div class="upload-img update" onclick="document.getElementById('avatarFile').click();">
							<img v-if="urlAvatar" :src="urlAvatar" class="img-circle"/>
							<img v-else src="../../../assets/user-circle.svg" class="img-circle"/>
							<div class="text">
								<p class="title">{{ $t('profile.profile_picture') }}</p>
								<div class="with-image"><icon-upload /><p>{{ $t('profile.upload_new_picture') }}</p></div>
							</div>
							<input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
						</div> -->
                        {{ $t('nomenclature.company.update') }}
					</div>
				</div>
				<div class="form crud">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('nomenclature.company.company_name') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.company.company_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="company.company_name != ''" @click.stop="company.company_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('nomenclature.company.company_name-ph')" class="input-text" v-model="company.company_name">
						</div>
					</div>
                    <div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('nomenclature.company.reg_no') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-cui /></div>
							<div class="icon-right" v-if="company.reg_no != ''" @click.stop="company.reg_no = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('nomenclature.company.reg_no-ph')" class="input-text" v-model="company.reg_no">
						</div>
					</div>
                    <div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.phone') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-phone /></div>
							<div class="icon-right" v-if="company.phone != ''" @click.stop="company.phone = ''"><icon-close class="icon-clear" /></div>
							<input type="number" :placeholder="$t('profile.phone_placeholder')" class="input-text phone" v-model="company.phone">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('nomenclature.company.country') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedCountry.$error}">
						<div class="icon-left"><icon-globe /></div>
							<multiselect
							class="select-tags-tbf language-profile"
							v-model="selectedCountry"
							label="name" 
							track-by="name" 
							:placeholder="$t('nomenclature.company.country-ph')" 
							:options="optionsCountries" 
							:option-height="104" 
							:show-labels="false"
							:allow-empty="false"
							:searchable="false"
							@input="selectLanguage">
								<template slot="singleLabel" slot-scope="props">
									<div class="optionLanguage">
										<span class="option__title">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="option" slot-scope="props">
									<div class="optionLanguage">
										<span class="option__title">{{ props.option.name }}</span>
									</div>
								</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.personal_data.town') }}*</label>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.company.city.$error}">
                            <div class="icon-left"><icon-city /></div>
                            <div class="icon-right" v-if="company.city != ''" @click.stop="company.city = ''"><icon-close class="icon-clear" /></div>
                            <input type="text" :placeholder="$t('salary.personal_data.town-input-ph')" class="input-text" v-model="company.city">
                        </div>
                    </div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('nomenclature.company.address') }}*</label>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.company.address.$error}">
                            <div class="icon-left"><icon-street /></div>
                            <div class="icon-right" v-if="company.address != ''" @click.stop="company.address = ''"><icon-close class="icon-clear" /></div>
                            <input type="text" :placeholder="$t('nomenclature.company.address-ph')" class="input-text" v-model="company.address">
                        </div>
                    </div>
					<div class="input-group w-100" style="position: relative;">
						<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
						<button id="buttonUpdate" class="btn-tbf blue btn-submit" @click="saveAction()">
							<div class="loader"></div>
							<span class="text">{{$t('general.update')}}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</div>
<loader-company-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconCui from '../../Icons/Cui'
    import IconPeople from '../../Icons/People'
	import iconPhone from '../../Icons/Phone'
	import IconGlobe from '../../Icons/Globe.vue'
    import IconCity from '../../Icons/City'
    import IconStreet from '../../Icons/Street'
	import { required } from 'vuelidate/lib/validators'
	import LoaderCompanyPage from '../../PagesLoaders/Company'
	export default {
		components: {
			IconClose,
            IconCui,
			IconPeople,
			iconPhone,
			IconGlobe,
            IconCity,
            IconStreet,
			LoaderCompanyPage
		},
		data() {
			return {
				loaded: false,
                selectedCountry: {},
				optionsCountries: [],
				company: {
                    company_name: '',
                    reg_no: '',
					phone: '',
                    country: '',
                    city: '',
                    address: ''
				},
				error_message: ''
			};
		},
		async mounted(){
            await this.getCountries();
			await this.getCompanyData();
			
			setTimeout(() => {
				$('.opacity-page').addClass('show')
				var title = this.$t('nomenclature-sidebar.company');
				this.$root.$emit("navbar_title", title);
			}, 0)

		},
		validations: {
			company: {
				company_name: {required},
				city: {required},
                address: {required}
			},
            selectedCountry: {required}
		},
		methods: {
            async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountries = Object.entries(data.data).map((el,index) =>{
						return{id: el[0], name:el[1]}
					})
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			async getCompanyData(){
				await axios.get('/instances/' + this.$auth.user().instance.id + '/edit')
				.then(({data}) => {
                    this.company.company_name = data.data.company_name
                    this.company.reg_no = data.data.reg_no ? data.data.reg_no : ''
                    this.company.phone = data.data.phone ? data.data.phone : ''
                    this.company.city = data.data.city ? data.data.city : ''
                    this.company.address = data.data.address ? data.data.address :''
                    this.selectedCountry = data.data.country ? this.optionsCountries.find(el => el.id == data.data.country) : ''
					this.company.country = this.selectedCountry ? this.selectedCountry.id : ''
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},

			selectLanguage(value){
				this.user.language = value.id
			},

			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = ''

				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {};

                    objData.company_name = this.company.company_name
                    objData.reg_no = this.company.reg_no
                    objData.phone = this.company.phone
                    objData.city = this.company.city
                    objData.address = this.company.address
                    objData.country = this.selectedCountry.id

					this.updateCompany(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			
			updateCompany(objData, btnSubmit, btnSubmitText, btnSubmitLoader){

				axios.patch('/instances/' + this.$auth.user().instance.id, objData,)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
							this.$auth.fetch().then(() => {
								this.$i18n.locale = this.$auth.user().language
								this.$cookies.set('language', this.$auth.user().language, '1m')
							})
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.avatar) {
								this.error_message = error.response.data.errors.avatar[0];
							}
						}, 1000)
					}, 300)})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},

			onAvatarChange(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.urlAvatar = URL.createObjectURL(files[0]);
				this.user.avatar = files[0]
			}
		}
	};
</script>

<style lang="scss" scoped>
	.content.form-group.profile{
		border-bottom: none;
        font-weight: 500;
	}
	.error-msg-settings {
		text-align-last: end;
		position: absolute;
		padding: 11px 0 5px 0;
		color: red;
		font-size: 12px;
		top: -25px;
	}
	input.input-text.phone::-webkit-outer-spin-button,
	input.input-text.phone::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>